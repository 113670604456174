const main = async () => {
	document.getElementById('copyright_year').innerHTML = new Date().getFullYear().toString();

	const request = new XMLHttpRequest();
	request.open('GET', 'https://gitlab.soraharu.com/api/v4/projects/5/releases');
	request.responseType = 'json';
	request.onload = function () {
		const responseJson: object = request.response;
		document.getElementById('version').innerHTML = responseJson[0].tag_name;

		const publishedDate = new Date(responseJson[0].released_at);
		document.getElementById('published').innerHTML = [
			publishedDate.getFullYear().toString(),
			(publishedDate.getMonth() + 1).toString(),
			publishedDate.getDate().toString(),
		].join('-');

		const setupwindowsia32Array = responseJson[0].assets.links.find(
			({name}) =>
				name.startsWith('Serendipity Setup ') && name.endsWith('.exe (windows-ia32)')
		);
		(document.getElementById('download_setup_windows_ia32') as HTMLAnchorElement).href =
			setupwindowsia32Array.url;

		const setupwindowsx64Array = responseJson[0].assets.links.find(
			({name}) => name.startsWith('Serendipity Setup ') && name.endsWith('.exe (windows-x64)')
		);
		(document.getElementById('download_setup_windows_x64') as HTMLAnchorElement).href =
			setupwindowsx64Array.url;

		const setupwindowsarm64Array = responseJson[0].assets.links.find(
			({name}) =>
				name.startsWith('Serendipity Setup ') && name.endsWith('.exe (windows-arm64)')
		);
		(document.getElementById('download_setup_windows_arm64') as HTMLAnchorElement).href =
			setupwindowsarm64Array.url;
	};
	request.send();
};

window.addEventListener('DOMContentLoaded', main);
